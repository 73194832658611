import React, { useState } from "react";

import Block from "../Block";
import Title from "../Title";

import { Project2 } from "../../ideas";
import Authors from "../../authors";

import "./index.css";
import MultiAssetPager from "../MultiAssetPager";
import { MdEdgesensorHigh, MdTouchApp } from "react-icons/md";
import CollapseIdeas from "../CollapseIdeas";

import CollapseBlock from "../CollapseBlock";
import CollapseButton from "../CollapseButton";

const InitialIdeas = Project2.initial;
const SensorVariations = Project2.sensorVariations;
const GestureMultitouchVariations = Project2.gestureMultitouchVariations;

const selectedSensorIndices = [8, 7, 1];
const selectedSensor = selectedSensorIndices.map(
  (index) => InitialIdeas[index].asset.image
);

const selectedGestureIndices = [3, 4, 5];
const selectedGesture = selectedGestureIndices.map(
  (index) => InitialIdeas[index].asset.image
);

const selectedIdeas = [
  {
    title: "Sensor Based",
    ideas: selectedSensor,
  },
  { title: "Gesture/Multitouch Based", ideas: selectedGesture },
];

const ProjectTwo = () => {
  const [showResultOne, setShowResultOne] = useState(true);
  const [showResultTwo, setShowResultTwo] = useState(true);

  return (
    <div className="">
      <Title
        title={'Alternative to "Slide to Unlock"'}
        subTitle="Project 2"
        authors={[Authors.Moe, Authors.Sofian]}
      />
      <Block reverse>
        <div>
          <h1 className="text-xl font-bold">The Premise...</h1>
          <p className="text-lg pl-6">
            <br />
            Sketch, design, implement and document via a portfolio an animated
            touch-based and sensor based interface for a mobile phone that is an
            alternative to ‘slide to unlock’.
          </p>
          <br />
          <p className="text-lg pl-6">
            You will design, implement, document and demonstrate an interface
            that provides an alternative way to unlock a mobile phone equipped
            with a multi-touch display and with multiple sensors.
          </p>
          <br />
          <p className="text-lg pl-6">In particular, you will:</p>
          <br />
          <ul className="list-disc pl-6">
            <li>
              <div>
                <p className="text-lg pl-6">
                  Develop two different techniques, with
                </p>
                <ul className="list-disc">
                  <li>
                    <p className="text-lg pl-6">
                      One based on gesture or multi-touch input and,
                    </p>
                  </li>
                  <li>
                    <p className="text-lg pl-6">
                      The other based on sensor input (e.g. for one-hand use)
                      Examples of sensors: accelerometer, gyroscope, light,
                      camera, etc.
                    </p>
                  </li>
                </ul>
              </div>
            </li>
            <li>
              <p className="text-lg pl-6">
                Use animation to provide feedback that the system is recognizing
                the action you are taking, and how the system then unlocks the
                device after the action is completed.
              </p>
            </li>
            <li>
              <p className="text-lg pl-6">
                Show how both techniques avoid accidental activation.
              </p>
            </li>
          </ul>
        </div>
      </Block>
      <CollapseIdeas
        title="Initial Ideas"
        id="initial"
        ideas={InitialIdeas}
        selected={[...selectedSensorIndices, ...selectedGestureIndices]}
      />
      <CollapseIdeas
        title="Variations"
        id="variation"
        ideas={InitialIdeas}
        selected={[...selectedSensorIndices, ...selectedGestureIndices]}
      />
      <Block reverse>
        <div className="w-full">
          <h1 className="text-4xl font-bold border-b-2 pb-6 mb-4 border-gray-500">
            Chosen ideas for further development:
          </h1>
          <p className="text-lg pl-6">
            We decided to sketch out variations for three designs for the sensor
            based ideas, and three designs for the gesture/multitouch ideas.
            After some consideration we decided to further implement{" "}
            <strong>Simon Says</strong>, <strong>Golf Unlock</strong>, and{" "}
            <strong>Vertical Degrees</strong> from the sensor based ideas, as
            well as <strong>Break In</strong>, <strong>Hold Bubble</strong>, and{" "}
            <strong>Binary</strong>.
          </p>
          <MultiAssetPager assets={selectedIdeas} />
          <p className="text-lg pl-6">
            We ended up with 30 sketches based on those six ideas, we then
            discussed amongst ourselves and decided that we would like to
            implement a variation of <strong>Simon Says</strong>, and a
            variation of Hold <strong>Bubble</strong>. The following illustrates
            the variations based on the selecteed ideas.
          </p>
        </div>
      </Block>
      <CollapseIdeas title="Sensor Variations" ideas={SensorVariations} />
      <CollapseIdeas
        title="Gesture Variations"
        ideas={GestureMultitouchVariations}
      />
      <Block className={showResultOne ? "" : "border-b border-gray-900"}>
        <div className="w-full flex flex-col md:flex-row justify-between">
          <div className="w-full  bottom-0 left-0 absolute flex justify-center">
            <CollapseButton
              onClick={() => setShowResultOne(!showResultOne)}
              collapsed={!showResultOne}
              className="flex items-end collapsed"
            />
          </div>
          <div>
            <h1 className="text-4xl font-bold">Decisions & Results</h1>
            Based on{" "}
            <a className="text-blue-700" href="#initial-9">
              Simon Says - Idea #9
            </a>
          </div>
          <div className="flex flex-row-reverse md:flex-row self-start md:self-center items-center">
            <MdEdgesensorHigh className="text-2xl" />
            <div className="pr-3 md:pl-3 md:pr-0">
              <p className="text-2xl">Sensor Based</p>
              <p>Gyroscope</p>
            </div>
          </div>
        </div>
      </Block>
      <CollapseBlock collapsed={!showResultOne}>
        <Block reverse>
          <div className="">
            <h1 className="text-3xl">Results</h1>
            <p>
              The final product can be found{" "}
              <a
                target="_blank"
                rel="noreferrer"
                className="text-blue-700"
                href="https://cpsc581.p2.2.osloz.com"
              >
                here
              </a>
              , and its source code is hosted on Github and can be found{" "}
              <a
                target="_blank"
                rel="noreferrer"
                className="text-blue-700"
                href="https://github.com/Tutadit/CPSC581-P2-Sensor"
              >
                here
              </a>
              . We have also recorded a demo video showcasing the idea:
            </p>
            <br />
            <iframe
              className="max-w-full"
              width="560"
              height="315"
              src="https://www.youtube.com/embed/F9b80QzxY3Q"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
          <div className="md:pr-12 md:max-w-half">
            <h1 className="text-3xl">Choosing the idea to develop</h1>
            <p>
              After careful consideration, we decided to implement a version of
              the <strong>Simon Says</strong> idea, having features of various
              variations. The light bars displayed will be like the ones shown
              in <strong>Variation #1.1</strong>. We will use the authentication
              concept of <strong>Variation #1.2</strong> in order to provide a
              security aspect to the design. We will also implement a way to
              play the pattern as in <strong>Variation #1.3</strong>, however
              rather than pull down to refresh we'll have a button for the
              functionality. We will also use the concept of feedback of{" "}
              <strong>Variation #1.5</strong>, however instead of a checkmark or
              cross, we will shake the screen contents when the pattern is
              detected to be wrong, if the pattern is right the phone unlocks.
            </p>
            <br />
            <h1 className="text-3xl">Implementation</h1>
            <p>
              Our teams web development skill-set varied widely; on the one side
              you have me, who's been fidgeting with web development for a long
              time; on the other side you have Mohammad and Sofian who are just
              being introduced to the concept. So, I took it upon myself to
              partly implement the system in a way that would challenge Sofian
              and Mohammad appropriately, and challenge them it did.
            </p>
          </div>
        </Block>
      </CollapseBlock>
      <Block className={showResultTwo ? "" : "border-b border-gray-900"}>
        <div className="w-full flex flex-col md:flex-row justify-between">
          <div className="w-full  bottom-0 left-0 absolute flex justify-center">
            <CollapseButton
              collapsed={!showResultTwo}
              onClick={() => setShowResultTwo(!showResultTwo)}
            />
          </div>
          <div>
            <h1 className="text-4xl font-bold">Decisions & Results</h1>
            Based on{" "}
            <a className="text-blue-700" href="#initial-5">
              Hold Bubble - Idea #5
            </a>
          </div>
          <div className="flex flex-row-reverse md:flex-row self-start md:self-center items-center">
            <MdTouchApp className="text-2xl" />
            <div className="pr-3 md:pl-3 md:pr-0">
              <p className="text-2xl">Gesture/Multitouch Based</p>
              <p>Tap & Hold</p>
            </div>
          </div>
        </div>
      </Block>
      <CollapseBlock collapsed={!showResultTwo}>
        <Block reverse>
          <div className="">
            <h1 className="text-3xl">Results</h1>
            <p>
              The final product can be found{" "}
              <a
                target="_blank"
                rel="noreferrer"
                className="text-blue-700"
                href="https://cpsc581.p2.1.osloz.com"
              >
                here
              </a>
              , and its source code is hosted on Github and can be found{" "}
              <a
                target="_blank"
                rel="noreferrer"
                className="text-blue-700"
                href="https://github.com/Tutadit/CPSC581-P2-GestureMultitouch"
              >
                here
              </a>
              . We have also recorded a demo video showcasing the idea:
            </p>
            <br />
            <iframe
              width="560"
              className="max-w-full"
              height="315"
              src="https://www.youtube.com/embed/e5yCr00qM7M"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
          <div className="md:pr-12 md:max-w-half">
            <h1 className="text-3xl">Choosing the idea to develop</h1>
            <br />
            <p>
              Even tho I would've loved to implement{" "}
              <strong>Variation #2.2</strong>, or better yet{" "}
              <strong>Variation #2.4</strong>, we decided on{" "}
              <strong>Variation #2.1</strong>; we did so because we thought
              Variation #2.2 was was more in part with our particular skill-set;
              since then, Variation #2.1 has proven itself to be a challenging
              task.
            </p>
            <br />
            <p>
              <strong>Variation #2.3</strong> did inspire us to add some colour
              to our concept; as we discussed implementation, we decided that
              the bubble should change colors as it reaches each ring, and so,
              it was so.
            </p>
            <br />
            <p>
              Upon implementing the system, as I thought of ways to make the
              system user friendly, I decided that the bubble should bounce as
              it hits each ring to allow for a better user experience entering a
              pattern, Sofian and Mohammad later agreed that it was a good idea.
            </p>
            <br />
            <p>
              I implemented the majority of this project, however I was able to
              reuse the clock interface from the Gesture/Multitouch project
              developed by Mohammad and Sofian.
            </p>
          </div>
        </Block>
      </CollapseBlock>
      <Block>Tha is all.</Block>
    </div>
  );
};

export default ProjectTwo;
