import CollaborativeMovieTVStreaming from "../../images/Project4/initial/Collaborative-Movie-TV-Streaming.jpg"
import CultureHub from "../../images/Project4/initial/Culture-Hub.png"
import FriendDetector from "../../images/Project4/initial/Friend-Detector.jpg"
import FriendRadio from "../../images/Project4/initial/Friend-Radio.png"
import InterviewPrep from "../../images/Project4/initial/Interview-Prep.jpg"
import LanguageTranslator from "../../images/Project4/initial/Language-Translator.jpg"
import MetaverseShopping from "../../images/Project4/initial/Metaverse-Shopping.jpg"
import NoPovertyLand from "../../images/Project4/initial/No-Poverty-Land.jpg"
import OffYourChest from "../../images/Project4/initial/Off-Your-Chest.jpg"
import VoiceRecognizedAudioPlayer from "../../images/Project4/initial/Voice-Recognized-Audio-Player.jpg"

import AudioVisualizer from "../../images/Project4/variations/AudioVisualizer.jpg"
import CriticalResources from "../../images/Project4/variations/CriticalResources.jpg"
import NaturalLanguageUnderstanding from "../../images/Project4/variations/NaturalLanguageUnderstanding.jpg"
import OffYourChestDeviceApp from "../../images/Project4/variations/OffYourChestDeviceApp.jpg"
import OffYourChestDevice from "../../images/Project4/variations/OffYourChestDevice.jpg"
import PostByKeyword from "../../images/Project4/variations/PostByKeyword.jpg"
import PostRecommendation from "../../images/Project4/variations/PostRecommendation.jpg"
import PostsByCategory from "../../images/Project4/variations/PostsByCategory.jpg"
import PostScheduling from "../../images/Project4/variations/PostScheduling.jpg"
import UserRating from "../../images/Project4/variations/UserRating.jpg"

const Assets = {
    CollaborativeMovieTVStreaming:{
        image: {
            src:CollaborativeMovieTVStreaming, 
            alt:"Collaborative Movie/TV Streaming with chat",
            type:"img"
        }
    },
    CultureHub:{
        image: {
            src:CultureHub, 
            alt:"Culture Hub",
            type:"img"
        }
    },
    FriendDetector:{
        image: {
            src:FriendDetector, 
            alt:"Friend Detector",
            type:"img"
        }
    },
    FriendRadio:{
        image: {
            src:FriendRadio, 
            alt:"Friend Radio",
            type:"img"
        }
    },
    InterviewPrep:{
        image: {
            src:InterviewPrep, 
            alt:"Interview Prep",
            type:"img"
        }
    },
    LanguageTranslator:{
        image: {
            src:LanguageTranslator, 
            alt:"Language Translator",
            type:"img"
        }
    },
    MetaverseShopping:{
        image: {
            src:MetaverseShopping, 
            alt:"Metaverse Shopping",
            type:"img"
        }
    },
    NoPovertyLand:{
        image: {
            src:NoPovertyLand, 
            alt:"No Poverty Land",
            type:"img"
        }
    },
    OffYourChest:{
        image: {
            src:OffYourChest, 
            alt:"Off Your Chest",
            type:"img"
        }
    },
    VoiceRecognizedAudioPlayer:{
        image: {
            src:VoiceRecognizedAudioPlayer, 
            alt:"Voice Recognized Audio Player",
            type:"img"
        }
    },
    AudioVisualizer:{
        image:{
            src:AudioVisualizer,
            alt:"Audio Visualizer",
            type:"img"
        }
    },
    CriticalResources:{
        image:{
            src:CriticalResources,
            alt:"Critical Resources",
            type:"img"
        }
    },
    NaturalLanguageUnderstanding:{
        image:{
            src:NaturalLanguageUnderstanding,
            alt:"Natural Language Understanding",
            type:"img"
        }
    },
    OffYourChestDeviceApp:{
        image:{
            src:OffYourChestDeviceApp,
            alt:"Off Your Chest Device App",
            type:"img"
        }
    },
    OffYourChestDevice:{
        image:{
            src:OffYourChestDevice,
            alt:"Off Your Chest Device",
            type:"img"
        }
    },
    PostByKeyword:{
        image:{
            src:PostByKeyword,
            alt:"Posts By Keyword",
            type:"img"
        }
    },
    PostRecommendation:{
        image:{
            src:PostRecommendation,
            alt:"Post Recommendation",
            type:"img"
        }
    },
    PostsByCategory:{
        image:{
            src:PostsByCategory,
            alt:"Posts By Category",
            type:"img"
        }
    },
    PostScheduling:{
        image:{
            src:PostScheduling,
            alt:"Post Scheduling",
            type:"img"
        }
    },
    UserRating:{
        image:{
            src:UserRating,
            alt:"User Rating",
            type:"img"
        }
    },
}


export default Assets