import React from "react";

import "./index.css";

const Block = React.forwardRef(
  ({ children, className, reverse = false }, ref) => {
    return (
      <div
        ref={ref}
        className={
          " p-8 md:pl-24 md:pr-24 md:pt-9 md:pb-9 text-grey-900 flex relative " +
          (reverse
            ? "flex-col md:flex-row-reverse "
            : "flex-col md:flex-row ") +
          (reverse ? "bg-gray-300 shadow-inner " : "bg-gray-200 ") +
          "justify-center items-start md:justify-start md:items-center " +
          className
        }
      >
        {children}
      </div>
    );
  }
);

export default Block;
