import React from "react";

import { BsChevronCompactDown, BsChevronCompactUp } from "react-icons/bs";

import "./index.css";

const CollapseButton = ({ onClick = null, collapsed }) => {
  console.log(onClick);
  return (
    <button
      onClick={() => onClick && onClick()}
      className="flex items-end collapsed text-gray-500 hover:text-gray-800"
    >
      {!collapsed ? (
        <>
          <BsChevronCompactUp className="text-sm m-0.5" />
          <BsChevronCompactUp className="text-sm m-0.5" />
          <BsChevronCompactUp className="text-sm m-0.5" />
          Collapse
          <BsChevronCompactUp className="text-sm m-0.5" />
          <BsChevronCompactUp className="text-sm m-0.5" />
          <BsChevronCompactUp className="text-sm m-0.5" />
        </>
      ) : (
        <>
          <BsChevronCompactDown className="text-sm m-0.5" />
          <BsChevronCompactDown className="text-sm m-0.5" />
          <BsChevronCompactDown className="text-sm m-0.5" />
          Expand
          <BsChevronCompactDown className="text-sm m-0.5" />
          <BsChevronCompactDown className="text-sm m-0.5" />
          <BsChevronCompactDown className="text-sm m-0.5" />
        </>
      )}
    </button>
  );
};

export default CollapseButton;
