import React from "react";

import "./index.css";

const SlideUpVariants = {
  show: {},
  hidden: {
    display: "none",
  },
};

const CollapseBlock = ({ children, className, collapsed }) => {
  return (
    <div
      className={"transition-all relative z-10" + className}
      style={!collapsed ? SlideUpVariants.show : SlideUpVariants.hidden}
    >
      {children}
    </div>
  );
};

export default CollapseBlock;
