import DonaldTrumpButton from "./images/DonaldTrumpButton.png";
import TheJocker from "./images/TheJocker.png";
import EdwardScissorHands from "./images/EdwardScissorHands.png";
import SeverusSnape from "./images/SeverusSnape.png";
import DwightSchrute from "./images/DwightSchrute.png";
import RyanReynolds from "./images/RyanReynolds.png";
import Karen from "./images/Karen.png";
import Karen2 from "./images/Karen2.png";
import Karen3 from "./images/Karen3.png";
import Pirate from "./images/Pirate.png";
import Pirate1 from "./images/Pirate1.png";
import Pirate2 from "./images/Pirate2.png";
import PirateMop from "./images/PirateMop.png";
import Hulk from "./images/Thehulk.png";
import Frank from "./images/Frank.png";
import SS from "./images/SS.png";
import ed from "./images/ed.png";
import ed1 from "./images/ed1.png";
import edd from "./images/edd.png";
import hs from "./images/hs.png";
import hs1 from "./images/hs1.png";
import uni from "./images/uni.jpeg";

import FillTheBucket from "./images/Project2/FillTheBucket/Fill_the_bucket.jpg";
import FillTheBucketVideo from "./images/Project2/FillTheBucket/Fill_the_bucket.mp4";
import FillTheBucketVideoFail from "./images/Project2/FillTheBucket/Fill_the_bucket_fail.mp4";

import SimonSays from "./images/Project2/SimonSays/Simon_says.jpg";
import SimonSaysVideo from "./images/Project2/SimonSays/Simon_says.mp4";
import SimonSaysVideoFail from "./images/Project2/SimonSays/Simon_says-fail.mp4";

import FlipTheSwitch from "./images/Project2/FlipTheSwitch/Flip_The_Switch_.jpg";
import FlipTheSwitchVideo from "./images/Project2/FlipTheSwitch/Flip_The_Switch_.mp4";

import KnockKnock from "./images/Project2/KnockKnock/Knock_Knock.jpg";
import KnockKnockVideo from "./images/Project2/KnockKnock/Knock_Knock.mp4";

import SpeakPassword from "./images/Project2/SpeakPassword/Speak_Password.jpg";
import SpeakPasswordVideo from "./images/Project2/SpeakPassword/Speak_Password.mp4";

import WinkAtCamera from "./images/Project2/WinkAtCamera/Wink_at_camera.jpg";
import WinkAtCameraVideo from "./images/Project2/WinkAtCamera/Wink_at_camera.mp4";

import SetTheTime from "./images/Project2/SetTheTime/Set_the_Time.jpg";
import SetTheTimeVideo from "./images/Project2/SetTheTime/Set_the_Time.mp4";

import StirCoffee from "./images/Project2/StirCoffee/stir_Coffee.jpg";
import StirCoffeeVideo from "./images/Project2/StirCoffee/stir_Coffee.mp4";

import JumpToTop from "./images/Project2/JumpToTop/Jump_To_The_Top.jpg";
import JumpToTopVideo from "./images/Project2/JumpToTop/Jump_To_The_Top.mp4";

import BreakIn from "./images/Project2/BreakIn/Break_In.jpg";
import BreakInVideo from "./images/Project2/BreakIn/Break_In.mp4";

import SpeakShapes from "./images/Project2/SpeakShapes.jpg";
import VerticalDegrees from "./images/Project2/VerticalDegrees_Sensor.jpg";
import TurnPhone from "./images/Project2/TurnPhone_Sensor.jpg";
import HoldBubble from "./images/Project2/HoldBubble_Gesture.jpg";
import Binary from "./images/Project2/Binary.jpg";
import Golf from "./images/Project2/golf.jpg";

import SimonSaysSlick from "./images/Project2/Simon_Says_Slick.jpg";
import SimonSaysSlickVideo from "./images/Project2/Simon_Says_Slick.mp4";
import SimonSaysVar from "./images/Project2/SimonSaysVar.jpg";
import SimonSaysDetail from "./images/Project2/SimonSaysDetail.jpg";
import SimonSaysBubble from "./images/Project2/Simon_Says_Bubble.jpg";
import SimonSaysBubbleVideo from "./images/Project2/Simon_Says_Bubble.mp4";
import SimonSaysVar1 from "./images/Project2/SimonSaysVar1.jpg";

import FillBubbleVar from "./images/Project2/FillBubbleVar.jpg";
import FillBubbleBaloon from "./images/Project2/Hold_To_Unlock_Pop_Balloon.jpg";
import FillBubbleBaloonVideo from "./images/Project2/Hold_To_Unlock_Pop_Balloon.mp4";
import HoldObjectVar from "./images/Project2/HoldObjectVar2.jpg";
import HoldBubbleVar from "./images/Project2/HoldBubbleVar4.jpg";
import BubbleVar from "./images/Project2/BubbleVar3.jpg";

import Tomato from "./images/p3/tomato.png";
import Stock from "./images/p3/stock.png";
import SitOn from "./images/p3/siton.png";
import EyeB from "./images/p3/eyeb.png";
import allEar from "./images/p3/allEar.jpg";
import inEye from "./images/p3/inEye.jpg";
import mood from "./images/p3/mood.jpg";
import audio from "./images/p3/audio.png";
import alarm from "./images/p3/alarm.png";
import closet from "./images/p3/closet.png";
import storyboard from "./images/p3/storyboard.mp4";

import BehindView from "./images/p3/var/BehindView.jpg";
import ShakeAwake from "./images/p3/var/ShakeAwake.png";
import PhoneSoundAwareness from "./images/p3/var/PhoneSoundAwareness.jpg";
import SpatialSoundAwarnessHarness from "./images/p3/var/SpatialSoundAwarnessHarness.jpg";
import Pomodoro from "./images/p3/var/Pomodoro.jpg";
import SpeechText from "./images/p3/var/SpeechText.png";
import RingShake from "./images/p3/var/RingShake.png";
import RefinedSketch from "./images/p3/var/RefinedSketch.jpg";
import HearingTheMoment from "./images/p3/var/HearingTheMoment.jpg";
import HouseHoldListener from "./images/p3/var/HouseHoldListener.jpg";
import MusicWithoutBarrier from "./images/p3/var/MusicWithoutBarrier.jpg";

import Assembling1 from "./images/p3/dev/Assembling1.MOV";
import gettingItTogether1 from "./images/p3/dev/getting-it-together1.jpg";
import Assembling from "./images/p3/dev/Assembling.MOV";
import gettingItTogether2 from "./images/p3/dev/getting-it-together2.jpg";
import CircuitBoard from "./images/p3/dev/CircuitBoard.png";
import firstGo from "./images/p3/dev/first-go.jpg";
import gettingItTogether from "./images/p3/dev/getting-it-together.jpg";
import AppVideo from "./images/p3/dev/App.mp4"

import Assembled from "./images/p3/dev/Assembled.MOV"

const Assets = {
  DonaldTrumpButton: {
    image: { src: DonaldTrumpButton, alt: "Donald Trump Button", type: "img" },
  },
  TheJocker: {
    image: { src: TheJocker, alt: "The Jocker", type: "img" },
  },
  EdwardScissorHands: {
    image: {
      src: EdwardScissorHands,
      alt: "Edward Scissor Hands",
      type: "img",
    },
  },
  SeverusSnape: {
    image: { src: SeverusSnape, alt: "Severus Snape", type: "img" },
  },
  DwightSchrute: {
    image: { src: DwightSchrute, alt: "Dwight Schrute", type: "img" },
  },
  RyanReynolds: {
    image: { src: RyanReynolds, alt: "Ryan Reynolds", type: "img" },
  },
  Karen: {
    image: [
      { src: Karen, alt: "Karen", type: "img" },
      { src: Karen2, alt: "Karen continued", type: "img" },
      { src: Karen3, alt: "Karen final", type: "img" },
    ],
  },
  Pirate: {
    image: { src: Pirate, alt: "Pirate", type: "img" },
  },
  PirateEnhanced: {
    image: [
      { src: Pirate1, alt: "Pirate Enhanced", type: "img" },
      { src: Pirate2, alt: "Pirate Enhanced continued", type: "img" },
    ],
  },
  PirateMop: {
    image: { src: PirateMop, alt: "PirateMop", type: "img" },
  },
  Hulk: {
    image: { src: Hulk, alt: "Hulk", type: "img" },
  },
  Frank: {
    image: { src: Frank, alt: "Frank", type: "img" },
  },
  SeverusSnapeEnhanced: {
    image: { src: SS, alt: "Severus Snape Enhanced", type: "img" },
  },
  EdwardScissorHandsEnhanced: {
    image: [
      { src: ed, alt: "Edward Scissor Hands Enhanced", type: "img" },
      {
        src: ed1,
        alt: "Edward Scissor Hands Enhanced...continued",
        type: "img",
      },
    ],
  },
  EdwardScissorHandsJim: {
    image: { src: edd, alt: "Edward Scissor Hands Jim", type: "img" },
  },
  HulkEnhanced: {
    image: [
      { src: hs, alt: "Hulk Enhanced", type: "img" },
      { src: hs1, alt: "Hulk Enhanced", type: "img" },
    ],
  },
  Unicyclist: {
    image: { src: uni, alt: "Unicyclist", type: "img" },
  },
  FillTheBucket: {
    image: { src: FillTheBucket, alt: "Fill The Bucket", type: "img" },
    video: {
      src: FillTheBucketVideo,
      alt: "Fill The Bucket Video",
      type: "video",
    },
    video_fail: {
      src: FillTheBucketVideoFail,
      alt: "Fill The Bucket Video Fail",
      type: "video",
    },
  },
  SimonSays: {
    image: { src: SimonSays, alt: "Simon Says", type: "img" },
    video: {
      src: SimonSaysVideo,
      alt: "Simon Says Video",
      type: "video",
    },
    video_fail: {
      src: SimonSaysVideoFail,
      alt: "Simon Says Video Fail",
      type: "video",
    },
  },
  FlipTheSwitch: {
    image: { src: FlipTheSwitch, alt: "Flip The Switch", type: "img" },
    video: {
      src: FlipTheSwitchVideo,
      alt: "Flip The Switch Video",
      type: "video",
    },
  },
  KnockKnock: {
    image: { src: KnockKnock, alt: "Knock Knock", type: "img" },
    video: {
      src: KnockKnockVideo,
      alt: "Knock Knock Video",
      type: "video",
    },
  },
  SpeakPassword: {
    image: { src: SpeakPassword, alt: "Speak Password", type: "img" },
    video: {
      src: SpeakPasswordVideo,
      alt: "Speak Password Video",
      type: "video",
    },
  },
  WinkAtCamera: {
    image: { src: WinkAtCamera, alt: "Wink At Camera", type: "img" },
    video: {
      src: WinkAtCameraVideo,
      alt: "Wink At Camera Video",
      type: "video",
    },
  },
  SetTheTime: {
    image: { src: SetTheTime, alt: "Set The Time", type: "img" },
    video: {
      src: SetTheTimeVideo,
      alt: "Set The Time Video",
      type: "video",
    },
  },
  StirCoffee: {
    image: { src: StirCoffee, alt: "Stir Coffee", type: "img" },
    video: {
      src: StirCoffeeVideo,
      alt: "Stir Coffee Video",
      type: "video",
    },
  },
  JumpToTop: {
    image: { src: JumpToTop, alt: "Jump To Top", type: "img" },
    video: {
      src: JumpToTopVideo,
      alt: "Jump To Top Video",
      type: "video",
    },
  },
  BreakIn: {
    image: { src: BreakIn, alt: "Break In", type: "img" },
    video: { src: BreakInVideo, alt: "Break In Video", type: "video" },
  },
  SpeakShapes: {
    image: { src: SpeakShapes, alt: "Speak Shapes", type: "img" },
  },
  VerticalDegrees: {
    image: { src: VerticalDegrees, alt: "Vertical Degrees", type: "img" },
  },
  TurnPhone: {
    image: { src: TurnPhone, alt: "Turn Phone", type: "img" },
  },
  HoldBubble: {
    image: { src: HoldBubble, alt: "Hold Bubble Gesture", type: "img" },
  },
  Binary: {
    image: { src: Binary, alt: "Binary Gesture", type: "img" },
  },
  Golf: {
    image: { src: Golf, alt: "Golf Gesture", type: "img" },
  },
  SimonSaysSlick: {
    image: { src: SimonSaysSlick, alt: "Simon Says Slick", type: "img" },
    video: { src: SimonSaysSlickVideo, type: "video" },
  },
  SimonSaysVar: {
    image: {
      src: SimonSaysVar,
      alt: "Simon Says Variation - Authenticate",
      type: "img",
    },
  },
  SimonSaysDetail: {
    image: { src: SimonSaysDetail, alt: "Simon Says Detail", type: "img" },
  },
  SimonSaysBubble: {
    image: { src: SimonSaysBubble, alt: "Simon Says Bubble", type: "img" },
    video: { src: SimonSaysBubbleVideo, type: "video" },
  },
  SimonSaysVar1: {
    image: { src: SimonSaysVar1, alt: "Simon Says Var 1", type: "img" },
  },
  FillBubbleVar: {
    image: { src: FillBubbleVar, alt: "Fill Bubble Var", type: "img" },
  },
  FillBubbleBaloon: {
    image: { src: FillBubbleBaloon, alt: "Fill Bubble Baloon", type: "img" },
    video: { src: FillBubbleBaloonVideo, type: "video" },
  },
  HoldObjectVar: {
    image: { src: HoldObjectVar, alt: "Hold Object Var", type: "img" },
  },
  HoldBubbleVar: {
    image: { src: HoldBubbleVar, alt: "Hold Bubble", type: "img" },
  },
  BubbleVar: {
    image: { src: BubbleVar, alt: "Bubble", type: "img" },
  },
  Tomato: {
    image: { src: Tomato, alt: "Pomodoro Timmer", type: "img" },
  },
  Stock: {
    image: { src: Stock, alt: "Crypto/Securities Lamp", type: "img" },
  },
  SitOn: {
    image: { src: SitOn, alt: "Sit Down Power Up", type: "img" },
  },
  EyeB: {
    image: { src: EyeB, alt: "Seeing Eye Band", type: "img" },
  },
  allEar: {
    image: { src: allEar, alt: "I'm all ears", type: "img" },
  },
  inEye: {
    image: { src: inEye, alt: "In your eyes", type: "img" },
  },
  mood: {
    image: { src: mood, alt: "Mood Ring", type: "img" },
  },
  audio: {
    image: { src: audio, alt: "Audio Visualizer", type: "img" },
  },
  alarm: {
    image: { src: alarm, alt: "Alarm System", type: "img" },
  },
  closet: {
    image: { src: closet, alt: "No hassle closet", type: "img" },
  },

  BehindView: {
    image: { src: BehindView, alt: "Sound spatial awareness", type: "img" },
  },
  ShakeAwake: {
    image: { src: ShakeAwake, alt: "Shake Awake", type: "img" },
  },
  PhoneSoundAwareness: {
    image: {
      src: PhoneSoundAwareness,
      alt: "Phone Sound Awareness",
      type: "img",
    },
  },
  SpatialSoundAwarnessHarness: {
    image: {
      src: SpatialSoundAwarnessHarness,
      alt: "Spatial Sound AwarnessHarness",
      type: "img",
    },
  },
  Pomodoro: {
    image: { src: Pomodoro, alt: "Pomodoro", type: "img" },
  },
  SpeechText: {
    image: { src: SpeechText, alt: "Speech Text", type: "img" },
  },
  RingShake: {
    image: { src: RingShake, alt: "Ring Shake", type: "img" },
  },
  RefinedSketch: {
    image: { src: RefinedSketch, alt: "Refined Sketch", type: "img" },
  },
  HearingTheMoment: {
    image: { src: HearingTheMoment, alt: "Hearing The Moment", type: "img" },
  },
  HouseHoldListener: {
    image: { src: HouseHoldListener, alt: "House-hold Listener", type: "img" },
  },
  MusicWithoutBarrier: {
    image: {
      src: MusicWithoutBarrier,
      alt: "Music Without Barrier",
      type: "img",
    },
  },
  storyboard: {
    video: { src: storyboard, alt: "Storyboard", type: "video", noPlay: true },
  },
  development: {
    any: [
      { src: firstGo, alt: "Gathering materials", type: "img" },
      { src: CircuitBoard, alt: "The Schematics", type: "img" },
      { src: gettingItTogether1, alt: "Plugging in", type: "img" },
      { src: gettingItTogether, alt: "Testing components", type: "img" },
      {
        src: Assembling1,
        alt: "Testing #1 - Motors",
        type: "video",
        noPlay: true,
      },
      {
        src: Assembling,
        alt: "Testing #2 - Motors with LCD",
        type: "video",
        noPlay: true,
      },
      { src: gettingItTogether2, alt: "Getting it wrapped", type: "img" },
      {
        src: AppVideo,
        alt: "App",
        type: "video",
        noPlay: true,
      },
      {
        src: Assembled,
        alt: "Final Product",
        type: "video",
        noPlay: true,
      },
    ],
  },
  selected: {
    image: [
      {
        src: allEar,
        alt: "Original Idea",
        type: "img",
      },
      {
        src: BehindView,
        alt: "Spacial Awarness",
        type: "img",
      },
      {
        src: PhoneSoundAwareness,
        alt: "Informative Messages",
        type: "img",
      },
      {
        src: SpatialSoundAwarnessHarness,
        alt: "Vibrational Feedback",
        type: "img",
      },
    ],
  },
};




export default Assets;
