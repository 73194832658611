import React from "react";

import Block from "../Block";
import Title from "../Title";

import Ideas from "./ideas";
import Authors from "../../authors";

import "./index.css";

import CollapseIdeas from "../CollapseIdeas";

const {
  premise,
  initial,
  variations,  
  storyboard,
  results,  
} = Ideas

const ProjectThree = () => {
  return (
    <div className="">
      <Title
        title={"Off My Chest"}
        subTitle="Anonymous audio platform"
        authors={[Authors.Moe, Authors.Sofian, Authors.Jian]}
      />
      <CollapseIdeas title="The Premise" ideas={premise} id="premise" />
      <CollapseIdeas title="Initial Ideas" ideas={initial} id="initial" />
      <CollapseIdeas title="Variations" ideas={variations} id="variation" odd />      
      <CollapseIdeas title="Storyboard" ideas={storyboard} id="storyboard" />
      <CollapseIdeas
        title="Results and Final Demo Video"
        ideas={results}
        id="results"
      />
      <Block>That is all.</Block>
    </div>
  );
};

export default ProjectThree;
