import React, { useState } from "react";

import { PacmanLoader } from "react-spinners";

import AssetElement from "../AssetElement";

import "./index.css";

const Asset = ({ asset }) => {
  const [loaded, setLoaded] = useState(false);

  return (
    <div className="relative w-full h-full flex items-center">
      {!loaded && (
        <div className="bg-white h-full w-full absolute inset-0 flex items-center justify-center">
          <h1 className="text-xl font-mono font-bold relative -left-6">
            Wasting time
          </h1>
          <div className="transform rotate-180 translate-x-11.5 translate-y-4.5">
            <PacmanLoader size={12} />
          </div>
        </div>
      )}      
      <AssetElement
        asset={asset}
        onLoad={() => {
          setLoaded(true);
        }}
      />
    </div>
  );
};

export default Asset;
