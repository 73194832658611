import React, { useState } from "react";

import "./index.css";

import Block from "../Block";
import CollapseBlock from "../CollapseBlock";
import CollapseButton from "../CollapseButton";
import AssetBlock from "../AssetBlock";

import { AiFillStar } from "react-icons/ai";

const CollapseIdeas = ({ title, ideas, selected, odd = false, id = null }) => {
  const [show, setShow] = useState(true);
  return (
    <>
      <Block className={"border-b border-t border-gray-400 bg-gray-200"}>
        <div className="w-full h-full">
          <h1 className="text-4xl font-bold">{title}</h1>
          <div className="w-full  bottom-0 left-0 absolute flex justify-center">
            <CollapseButton collapsed={!show} onClick={() => setShow(!show)} />
          </div>
        </div>
      </Block>
      <CollapseBlock collapsed={!show}>
        {ideas.map((idea, i) => (
          <AssetBlock
            key={i}
            asset={idea.asset}
            title={idea.title}
            subTitle={idea.subTitle}
            author={idea.author}
            odd={odd ? i % 2 : !(i % 2)}
            reverse={odd ? i % 2 : !(i % 2)}
            id={id && id + "-" + (i + 1)}
            badge={
              selected &&
              selected.reduce(
                (prev, current) => prev || current === i,
                false
              ) ? (
                <>
                  <AiFillStar />
                </>
              ) : null
            }
          >
            {idea.content}
          </AssetBlock>
        ))}
        <div className="w-full absolute z-20 bottom-0 flex justify-center">
          <CollapseButton collapsed={!show} onClick={() => setShow(!show)} />
        </div>
      </CollapseBlock>
    </>
  );
};

export default CollapseIdeas;
