import React from "react";

import "./index.css";

import Block from "../Block";

const Title = ({ title, subTitle = null, authors = null }) => {
  const authors_is_list = authors && authors.length;
  return (
    <Block className="relative h-40">
      <div>
        {subTitle && <h1 className="md:text-xl">{subTitle}</h1>}
        <h1 className="text-xl md:text-3xl font-bold">{title}</h1>
        {authors_is_list && (
          <h1>
            With:{" "}
            {authors
              .filter((author) => author && author.name)
              .map((author, i) => (
                <span key={i}>
                  {author.url ? (
                    <a
                    target="_blank"
                    rel="noreferrer"
                    href={author.url}
                    className="text-blue-700"
                    >
                      {author.name}
                    </a>
                  ) : (
                    author.name
                  )}
                  {((i === authors.length - 2)) ? " and " : i < authors.length - 2 ? ", " : ""}
                </span>
              ))}
          </h1>
        )}
      </div>
    </Block>
  );
};

export default Title;
