import React, { useState } from "react";

import { AiFillCaretLeft, AiFillCaretRight } from "react-icons/ai";

import "./index.css";

import MultiAssets from "../MultiAssets";

const MultiAssetPager = ({ assets }) => {
  const [current, setCurrent] = useState(0);

  if (
    (!assets ||
      !assets.length ||
      assets.length === 0 ||
      assets.reduce((prev, curr) => prev || !curr.ideas),
    false)
  )
    return;

  return (
    <div className="flex">
      <button
        className={
          (current > 0 ? "" : "cursor-default text-gray-500") + " text-3xl"
        }
        onClick={() => {
          if (current > 0) setCurrent(current - 1);
        }}
      >
        <AiFillCaretLeft />
      </button>
      <MultiAssets
        title={assets[current].title}
        assets={assets[current].ideas}
      />
      <button
        className={
          (current < assets.length - 1 ? "" : "cursor-default text-gray-500") + " text-3xl"
        }
        onClick={() => {
          if (current < assets.length - 1) setCurrent(current + 1);
        }}
      >
        <AiFillCaretRight />
      </button>
    </div>
  );
};

export default MultiAssetPager;
