import React from "react";
import Assets from "./assets";
import Authors from "./authors";
import Author from "./components/Author";
import Idea from "./components/Idea";

export const Project3 = {
  premise: [
    {
      content: (
        <>
          <p className="text-lg pl-6">
            For this project, you will create a physical prototype to solve an
            everyday problem. Examples of everyday problems could be a device to
            show how to dress for the weather of the day, an alarm system for
            your diary, an anti-theft device for your wallet, workout timer,
            etc.
          </p>
          <br />
          <p className="text-lg pl-6">Rules:</p>
          <br />
          <ol className="list-disc pl-6">
            <li>
              <p className="text-lg pl-6">
                You must have at least one output and input device (see sensor
                sheet)
              </p>
            </li>
            <li>
              <p className="text-lg pl-6">
                All wires/boards must be hidden (anything that does not need to
                be exposed is covered) by using physical prototyping
              </p>
            </li>
          </ol>
          <br />
          <p className="text-lg pl-6">
            Be creative. While your product should be functional and relevant,
            it should also be aesthetic, engaging and intriguing within the
            scope of your audience.
          </p>
          <br />
          <p className="text-lg pl-6">
            You also need to sketch a narrative storyboard of the final design
            and all the interactions with the design
          </p>
        </>
      ),
    },
  ],
  initial: [
    {
      title: "Time management - Pomodoro technique",
      subTitle: "Idea #1 - Touch pad & Beeper",
      author: Authors.Sofian,
      asset: Assets.Tomato,
      content: (
        <>
          <p>
            A smart timer designed to follow the pomodoro technique of time
            management. It utilizes a touch pad as the input from the user,
            where the timer begins by tapping on the tomato.
          </p>
          <br />
          <p>
            It uses a beeper to indicate when the user should take a break, the
            break time is indicated by the number of beeps.
          </p>
        </>
      ),
    },
    {
      title: "Cryptocurrency Alert System",
      subTitle: "Idea #2 - WiFi Board & RGB Light",
      author: Authors.Sofian,
      asset: Assets.Stock,
      content: (
        <>
          <p>
            A desk lamp where the colour of the light is indicative of the
            status of the user's selected market securites.
          </p>
          <br />
          <p>
            The lamp will connect to the WiFi and will repeadatly fetch market
            information and change the color of the RGB light depending on the
            overall move for the selected securities.
          </p>
        </>
      ),
    },
    {
      title: "Sit down, Power up",
      subTitle: "Idea #3 - Switch & Relay",
      author: Authors.JP,
      asset: Assets.SitOn,
      content: (
        <>
          <p>
            A simple switch placed on the user's desktop chair, the switch is
            activated when the user sits down on their chair.
          </p>
          <br />
          <p>
            The switch will activate a relay that will then power on the PC.
          </p>
        </>
      ),
    },
    {
      title: "The seeing eye-band",
      subTitle: "Idea #4 - Camera & Air pump",
      author: Authors.JP,
      asset: Assets.EyeB,
      content: (
        <>
          <p>
            An eye band aimed to provide spacial feedback to blind individuals.
            It works by utilizing a camera to map the world around the user.
          </p>
          <br />
          <p>
            The eye-band is comprised of various pockets with air tubes routed
            to each of them. With the use of an airpump and a routing system we
            can inflate each pocket individually, being able to provide a sense
            of the space around you by feeling the presure from the band.
          </p>
        </>
      ),
    },
    {
      title: "I am all ears",
      subTitle: "Idea #5 - Microphone & Vibrator motor",
      author: Authors.Jian,
      asset: Assets.allEar,
      content: (
        <>
          <p>
            Provide sound awareness to those who are deaf or hard of hearing. It
            works by listening to the sorroundings and determining when specific
            types of sound occur.
          </p>
          <br />
          <p>
            The user will be able to set specific vibration patterns for each
            type of sound they want to be alerted of.
          </p>
        </>
      ),
    },
    {
      title: "In your eyes",
      subTitle: "Idea #6 - Camera & Speaker",
      author: Authors.Jian,
      asset: Assets.inEye,
      content: (
        <>
          <p>
            Provide spatial awarness to blind people by providing auditory
            feedback regarding the sorrounded space
          </p>
          <br />
          <p>
            It works by using the camera to map the space around the user, we
            then utilize AI to provide various auditory messages to guide the
            blind person in 3D space.
          </p>
        </>
      ),
    },
    {
      title: "Mood Ring",
      subTitle: "Idea #7 - PPG Sensor & GSR Sensor",
      author: Authors.Jian,
      asset: Assets.mood,
      content: (
        <>
          <p>
            Help those who suffer with depression or anxiety by tracking their
            stress level.
          </p>
          <br />
          <p>
            We utilize the PPG Sensor and GSR sensor to monitor the user's
            stress levels. This sensors would be embedded on a ring.
          </p>
        </>
      ),
    },
    {
      title: "Audio Visualizer",
      subTitle: "Idea #8 - USB & LCD Screen",
      author: Authors.Moe,
      asset: Assets.audio,
      content: (
        <>
          <p>
            An audio visualizer that uses an LCD screen to display the audio
            being played on your phone
          </p>
          <br />
          <p>
            Plug in your phone to the device and play any music, the device will
            detect the music played and display it's various level changes as
            bars or lines on the screen.
          </p>
        </>
      ),
    },
    {
      title: "Alarm System",
      subTitle: "Idea #9 - Motion sensor & Beeper",
      author: Authors.Moe,
      asset: Assets.alarm,
      content: (
        <>
          <p>
            This device would activate a beeper when motion is detected, making
            it useful as an alarm system.
          </p>
          <br />
          <p>It uses a motion detector and a beeper to achieve this.</p>
        </>
      ),
    },
    {
      title: 'Smart "No Hassle" closet',
      subTitle: "Idea #10 - Camera, Motors, Air Pump",
      author: Authors.JP,
      asset: Assets.closet,
      content: (
        <>
          <p>
            A closet that allows you to browse your clothes in full display. Use
            your arm to gesture what pieces you would like to rotate through.
          </p>
          <br />
          <p>
            The idea is to use a collection of belts and air pumps to get the
            clothes to stick to the belt.
          </p>
        </>
      ),
    },
  ],
  variations: [
    {
      title: "Sound Spatial Awarness - Headphones",
      subTitle: (
        <p>
          Variation #1 - on <Idea id="initial-5" name={'"I am all Ears"'} />
        </p>
      ),
      author: Authors.Moe,
      asset: Assets.BehindView,
      content: (
        <>
          <p>
            A variation on Jians "I'm all ears" idea; where, instead of a
            headset, it is headphones that vibrate depending on the sound
            arround the user, in order to provide sound awarness.
          </p>
          <br />
          <p>
            There are three vibrator motors, one on each earphone and one on the
            middle of the wire connecting both earphones.
          </p>
        </>
      ),
    },
    {
      title: "Shake Awake",
      subTitle: (
        <p>
          Variation #2 - on <Idea id="initial-5" name={'"I am all Ears"'} />
        </p>
      ),
      author: Authors.JP,
      asset: Assets.ShakeAwake,
      content: (
        <>
          <p>
            This variation aims at providing those who are deaf or hard of
            hearing with an efficient way to wake up on time.
          </p>
          <br />
          <p>
            It is a sheet, that is placed under the matress, that shakes when
            activated. The user would be able to set the time they wish to
            activate it, as well as the strength of the shake.
          </p>
        </>
      ),
    },
    {
      title: "Phone Sound Compass",
      subTitle: (
        <p>
          Variation #3 - on <Idea id="initial-5" name={'"I am all Ears"'} />
        </p>
      ),
      author: Authors.Moe,
      asset: Assets.PhoneSoundAwareness,
      content: (
        <>
          <p>
            With this variation, the user is able to use their phone as a sound
            compass. Where they will be able to see various sounds as they occur
            around them.
          </p>
          <br />
          <p>
            It utilizes the phone's microphone and accelerometer to display,
            within the edge of the screen, a message regarding the sound coming
            from the direction the edge points to.
          </p>
        </>
      ),
    },
    {
      title: "Spatial Sound Awarness",
      subTitle: (
        <p>
          Variation #4 - on <Idea id="initial-5" name={'"I am all Ears"'} />
        </p>
      ),
      author: Authors.Moe,
      asset: Assets.SpatialSoundAwarnessHarness,
      content: (
        <>
          <p>
            This is a band that is able to be placed on any limb. It has
            vibrator motors that provide vibrational feedback as well as
            direction of sounds around the user.
          </p>
          <br />
          <p>
            It uses vibrator motors to indicate where and what type of sound it
            detected.
          </p>
        </>
      ),
    },
    {
      title: "Speech Text",
      subTitle: (
        <p>
          Variation #5 - on <Idea id="initial-5" name={'"I am all Ears"'} />
        </p>
      ),
      author: Authors.JP,
      asset: Assets.SpeechText,
      content: (
        <>
          <p>
            This one is intended to be used to get a live dictation of speech
            from whithin a close range.It is intended to be used by the deaf in
            cases where they are unable to read lips.
          </p>
          <br />
          <p>It utilizes an LCD screen and a microphone to display speech.</p>
        </>
      ),
    },
    {
      title: "Ring Shake",
      subTitle: (
        <p>
          Variation #6 - on <Idea id="initial-5" name={'"I am all Ears"'} />
        </p>
      ),
      author: Authors.JP,
      asset: Assets.RingShake,
      content: (
        <>
          <p>
            This variation utilizes a small vibrator motor in a ring, as well as
            a microphone, to listen to, and recognize sounds around the user.
          </p>
          <br />
          <p>
            The user is able to set vibration patterns for avrious different
            sounds around them.
          </p>
        </>
      ),
    },
    {
      title: "Refined Sketch",
      subTitle: (
        <p>
          Variation #7 - on <Idea id="initial-5" name={'"I am all Ears"'} />
        </p>
      ),
      author: Authors.Jian,
      asset: Assets.RefinedSketch,
      content: (
        <>
          <p>
            Here is a sketch of system implementation for the{" "}
            <Idea id="initial-5" name={'"I am all Ears"'} /> idea, this design
            was inspired by our various variations and brainstorming sessions.
          </p>
          <br />
          <p></p>
        </>
      ),
    },
    {
      title: "Hearing The Moment",
      subTitle: (
        <p>
          Variation #8 - on <Idea id="initial-5" name={'"I am all Ears"'} />
        </p>
      ),
      author: Authors.Jian,
      asset: Assets.HearingTheMoment,
      content: (
        <>
          <p>
            Another idea that was inspired by "I'm all Ears" was too record when
            a sound that is of significance to the user, such as Laughter or
            Cheering, occurs.
          </p>
          <br />
          <p>
            We would display the timeline of significant sound to the user
            through the use of an app. Allowing the user to have important
            memories saved.
          </p>
        </>
      ),
    },
    {
      title: "House-hold Listener",
      subTitle: (
        <p>
          Variation #9 - on <Idea id="initial-5" name={'"I am all Ears"'} />
        </p>
      ),
      author: Authors.Jian,
      asset: Assets.HouseHoldListener,
      content: (
        <>
          <p>
            This is similar to the "I am all Ears" idea, but instead of a
            wearable, it is a home pod device. The device listens for import
            sounds around the house, such as water running or the suspicion of
            an intruder.
          </p>
          <br />
          <p>
            The device would send notifications to the user's phone and alert
            them when the sounds are detected.
          </p>
        </>
      ),
    },
    {
      title: "Music Without Barrier",
      subTitle: (
        <p>
          Variation #10 - on <Idea id="initial-5" name={'"I am all Ears"'} />
        </p>
      ),
      author: Authors.Jian,
      asset: Assets.MusicWithoutBarrier,
      content: (
        <>
          <p>
            Deaf people are able to enjoy music by feeling the music as
            vibration, this idea is a hand-band that will provide the user with
            vibrational feedback on the music that is playing around them.
          </p>
          <br />
          <p>
            Deaf people could use this to practive their singing too, as the
            device would pick up the notes being sung and provide accurate
            feedback via vibrations.
          </p>
        </>
      ),
    },
  ],
  selected: [
    {
      title: "I am all Ears",
      subTitle: "Auditory awarness smart handband",
      asset: Assets.selected,
      content: (
        <>
          <p>
            From the start of the brainstorming process, we where drawn towards{" "}
            <Author author={Authors.Jian} short />
            {"'s "}
            <Idea id="initial-5" name={'"I am all Ears"'} /> idea. After
            developing various variations on the concept, we came to realize
            that there were three aspects presented that showed great potential:
            vibrational feedback, graphical feedback, and portability.
          </p>
          <br />
          <p>
            We decided to implement a hand-band equipped with vibrational
            motors, and an lcd. Our goal was to utilize the motors as
            vibrational feedback on the wrist of the user, as well as providing
            informative messages regarding the various sounds detected via an
            LCD screen.
          </p>
        </>
      ),
    },
  ],
  storyboard: [
    {
      title: "I am all Ears",
      subTitle: "Storyboard and Concept Video",
      asset: Assets.storyboard,
      content: (
        <>
          <p>
            Here we present out storyboard and concept video, where we outline
            the idea of our product as it is used on an average day.
          </p>
          <br />
          <p>
            We also present various ideas that can be implemented to further
            expand the project, such as the "Home Device", where the device
            would listen for any important sounds and notify the user via a
            phone notification.
          </p>
        </>
      ),
    },
  ],
  development: [
    {
      title: "Our journey",
      asset: Assets.development,
      content: (
        <>
          <p>
            In orer to make our device as small as possible, we decided to use a
            small microcontroller, specifically the WEMOS D1 mini board. It's
            wifi features proved to be useful. We also utilized 3 vibration
            motors and an LCD screen.
          </p>
          <br />
          <p>
            We designed and implemented an enclosure for our prototype that
            would allow us to wear the device on our wrists. We used an elastic
            fabric as the wrist band, and we fitted the band with the three
            vibration motors.
          </p>
          <br />
          <p>
            We also implemented an app with a model trained, with Google's
            Trachable Machine, to recognize various everyday important sounds.
          </p>
          <br />
          <p>
            We then set up an MQTT server and connected the app to it. We were
            able to send messages to the device in order to display content on
            the screen and vibrate any of the three motors.
          </p>
        </>
      ),
    },
  ],
  results: [
    {
      title: "I am all Ears - Handband",
      content: (
        <div className="w-full flex">
          <div>
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/qfRiqo7C8o0"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
              allowfullscreen
            ></iframe>
          </div>
          <div className="p-6">
            <p>
              Here we present the Final Demo Video for our project. Our final
              arduino code can be found{" "}
              <a
                className="text-blue-700"
                href="https://github.com/Tutadit/CPSC581---I-m-all-ears"
                target="_blank"
                rel="noreferrer"
              >
                here
              </a>
              . And the app source code can be found{" "}
              <a
                className="text-blue-700"
                href="https://github.com/JLiao98/im_all_ears"
                target="_blank"
                rel="noreferrer"
              >
                here
              </a>
              .
            </p>
          </div>
        </div>
      ),
    },
  ],
};
export const Project2 = {
  initial: [
    {
      title: "Speak Shapes",
      subTitle: "Idea #1 - Microphone",
      author: Authors.Moe,
      asset: Assets.SpeakShapes,
      content: (
        <>
          <p>
            When waking up your phone you are presented with 4 random basic
            shapes, in order to unlock your phone you must speak out loud the
            names of each of the shapes in the order in which the are displayed.
          </p>
          <br />
          <p>
            Accidental unlock is improbable so long as the speech to text system
            is strong enough.
          </p>
        </>
      ),
    },
    {
      asset: Assets.VerticalDegrees,
      title: "Tilt phone vertically to a specific degree",
      subTitle: "Idea #2 - Gyroscope ",
      author: Authors.Sofian,
      content: (
        <>
          <p>
            The user is presented with a lock screen showing the current
            vertical tilt of the phone in degrees, In order to unlock the phone
            the user must tilt their phone to a pre-defined degree.
          </p>
          <br />
          <p>
            The user should hold the phone at the specified degree for some time
            in order to prevent accidental unlock.
          </p>
        </>
      ),
    },
    {
      asset: Assets.TurnPhone,
      title: "Turn the phone like a key",
      subTitle: "Idea #3 - Gyroscope",
      author: Authors.Sofian,
      content: (
        <>
          <p>
            Here, In order to unlock your phone you must pretend like you are
            unlocking a lock in front of you and your phone is the key, once the
            movement of unlocking has been registered, the phone unlocks.
          </p>
          <br />
          <p>
            Given the complexity of the movement, accidental unlock is
            improbable.
          </p>
        </>
      ),
    },
    {
      asset: Assets.BreakIn,
      title: "Break In",
      subTitle: "Idea #4 - Multi-Touch",
      author: Authors.JP,
      content: (
        <>
          <p>
            The gist of it is: You must break the glass to access your phone, so
            by taping on your phone you break the glass. Three taps breaks
            through.
          </p>
          <br />
          <p>
            In order to make the idea withstand accidental unlock, the glass
            should self repair after some time.
          </p>
        </>
      ),
    },
    {
      asset: Assets.HoldBubble,
      title: "Hold Bubble",
      subTitle: "Idea #5 - Gesture",
      author: Authors.Sofian,
      content: (
        <>
          <p>
            Hold the bubble on the screen for a few seconds to fill it up, once
            it is full, your phone unlocks.
          </p>
          <br />
          <p>
            The time to fill up the bubble would have to be long enough to make
            accidental unlock improbable, but short enough to be a quick way to
            access your phone.
          </p>
        </>
      ),
    },
    {
      asset: Assets.Binary,
      title: "Binary to Decimal Conversion",
      subTitle: "Idea #6 - Gesture",
      author: Authors.Moe,
      content: (
        <>
          <p>
            Convert the presented binary number into decimal to unlock your
            phone. This is great for learning binary!
          </p>
          <br />
          <p>
            At first you might take a while to unlock your phone, once you're
            good this will be a quick unlock.
          </p>
        </>
      ),
    },
    {
      asset: Assets.JumpToTop,
      title: "Jump to the Top",
      subTitle: "Idea #7 - Gyroscope",
      author: Authors.JP,
      content: (
        <>
          <p>
            This time in order to unlock the Phone you must control the jumping
            man and get him to jump onto the platforms all the way to the top
          </p>
          <br />
          <p>
            The platforms need be small enough to prevent the jumping man from
            reaching the top on accident.
          </p>
        </>
      ),
    },

    {
      asset: Assets.Golf,
      author: Authors.Moe,
      title: "Golf",
      subTitle: "Idea #8 - Gesture + Gyroscope",
      content: (
        <>
          <p>
            To unlock your screen you must first tap on the screen to get the
            ball moving, then you steer the ball by tilting your phone left or
            right, use this to get the ball in the hole. Once the ball is in the
            hole your phone unlocks.
          </p>
          <br />
          <p>
            The position of the hole should be placed such that if the ball is
            activated on accident, the chances of it making it in the hole are
            slim.
          </p>
        </>
      ),
    },
    {
      asset: Assets.SimonSays,
      author: Authors.JP,
      title: "Simon Says",
      subTitle: "Idea #9 - Gyroscope",
      content: (
        <>
          <p>
            This next idea also uses the gyroscope with a Simon Says twist to
            it. The idea is to show a pattern and have the user tilt their phone
            in order to recreate the pattern. If the pattern is wrong, the phone
            does not unlock, making an accidental unlock inprobable.
          </p>
        </>
      ),
    },
    {
      asset: Assets.StirCoffee,
      title: "Stir The Coffee",
      author: Authors.JP,
      subTitle: "Idea #10 - Gesture",
      content: (
        <>
          <p>
            For those coffee lovers out there, this one is going to be familiar.
            In order to unlock your phone you must the coffe until the cream is
            evenly dispersed.
          </p>
          <br />
          <p>
            To prevent accidental unlocks, the cream will set to the bottom of
            the cup after some time
          </p>
        </>
      ),
    },
  ],
  gestureMultitouchVariations: [
    {
      asset: Assets.FillBubbleVar,
      title: "Fill Bubble Authentication",
      author: Assets.Sofian,
      subTitle: "Variation #2.1",
      content: (
        <>
          <p>
            In order to add an authentication system to the design, there will
            be a few rings around the bubble. Passwords are entered by taping
            and holding to expand the bubble and letting it go to shrink the
            bubble to a preset pattern of rings.
          </p>
        </>
      ),
    },
    {
      asset: Assets.FillBubbleBaloon,
      title: "Fill Bubble Pop The Baloon",
      author: Assets.JP,
      subTitle: "Variation #2.2",
      content: (
        <>
          <p>
            Unlock your phone by poping the baloon. Do this by taping and
            holding to fill the baloon with more air until it pops. If you let
            go it will deflate.
          </p>
        </>
      ),
    },
    {
      asset: Assets.HoldObjectVar,
      title: "Tap and Hold to fill circles",
      author: Assets.Moe,
      subTitle: "Variation #2.3",
      content: (
        <>
          <p>
            User has to tap and hold each circle to fill, and they must fill all
            circles (in no order specifically), however this can be changed so
            the user can set a unique pattern on what circles to fill in what
            order.
          </p>
        </>
      ),
    },
    {
      asset: Assets.HoldBubbleVar,
      title: "Climb to the top",
      author: Assets.Sofian,
      subTitle: "Variation #2.4",
      content: (
        <>
          <p>
            Tap and hold to get the climber to climb to the top, if you let go,
            he falls. When he reaches the top your phone unlocks.
          </p>
        </>
      ),
    },
    {
      asset: Assets.BubbleVar,
      title: "Screen Down",
      author: Assets.Sofian,
      subTitle: "Variation #2.5",
      content: (
        <>
          <p>
            Tap and hold to get the lock screen to slide down and unock your
            phone.
          </p>
        </>
      ),
    },
  ],
  sensorVariations: [
    {
      asset: Assets.SimonSaysSlick,
      title: "Simon Says Slick",
      author: Authors.JP,
      subTitle: "Variation #1.1",
      content: (
        <>
          <p>
            Keeping the same concept as the original idea, I wanted to
            incorporate a more modern look to it, rather than solid blocks
          </p>
        </>
      ),
    },
    {
      asset: Assets.SimonSaysVar,
      title: "Simon Says Authenticate with Pattern",
      author: Authors.Sofian,
      subTitle: "Variation #1.2",
      content: (
        <>
          <p>
            In order to implement a security aspect, the pattern will not be
            shown to the user for them to repeat, rather it'll be used as the
            password to unlock the phone. This pattern would be set by the user.
          </p>
        </>
      ),
    },
    {
      asset: Assets.SimonSaysDetail,
      title: "Simon Says Pull down to replay pattern",
      author: Authors.Sofian,
      subTitle: "Variation #1.3",
      content: (
        <>
          <p>
            The user can pull down to replay the pattern. This would be a
            passworless entry and therefore not secure in nature.
          </p>
        </>
      ),
    },
    {
      asset: Assets.SimonSaysBubble,
      title: "Simon Says Bubbles",
      author: Authors.JP,
      subTitle: "Variation #1.4",
      content: (
        <>
          <p>
            Instead of rectangles on the sides, there is bubbles and a ball in
            the middle. When the phone is tilted the ball falls towards where
            the user tilts. When the ball reaches a bubble, it makes the bubble
            animates to be squished by the ball and it activates it.
          </p>
        </>
      ),
    },
    {
      asset: Assets.SimonSaysVar1,
      title: "Simon Says Feedback on wrong pattern",
      author: Authors.Moe,
      subTitle: "Variation #1.5",
      content: (
        <>
          <p>
            Right/Wrong: Shows the user as they performing the pattern whether
            they are right or wrong in the pattern they are mimicking
          </p>
        </>
      ),
    },
  ],
};
