import React  from "react";

import "./index.css";
import Asset from "../Asset";

const MultiAssets = ({ title, assets }) => {

  if (!assets || !assets.length) return <></>;

  return (
    <div className="pt-4 pb-4">
      <h1 className="text-xl w-full">{title}</h1>
      <div className=" flex flex-col md:flex-row items-center justify-between flex-nowrap">
        {assets.map((asset) => (
          <div className="md:w-1/3 p-3" key={asset.alt}>
            <div>
              <Asset asset={asset} />
            </div>
            <h1 className="text-lg pt-3 w-full text-center">{asset.alt}</h1>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MultiAssets;
