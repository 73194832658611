import React, { useState } from "react";

import { NavLink } from "react-router-dom";
import { ImMenu } from "react-icons/im";
import { IoClose } from "react-icons/io5";
import "./index.css";

import Avatar from "../../images/avatar.png";

const Navigation = () => {
  const [showMenu, setShowMenu] = useState(false);

  return (
    <div className="relative flex md:justify-between justify-center md:items-center flex-col md:flex-row bg-gray-300 z-10">
      <div className="p-6 md:p90 flex justify-between">
        <div className="flex items-center">
          <div className="w-16">
            <img src={Avatar} alt="Avatar" />
          </div>
          <div>
            <h1 className="text-lg font-bold">Juan Pablo Lozano Sarmiento</h1>
            <h2>
              <a
                className="text-blue-700"
                href="https://contacts.ucalgary.ca/info/cpsc/courses/f21/CPSC581?destination=profiles%2F1-10572305"
                target="_blank"
                rel="noreferrer"
              >
                CPSC 581
              </a>{" "}
              - Portfolio
            </h2>
          </div>
        </div>
        {showMenu ? (
          <button
            className="text-3xl md:hidden"
            onClick={() => {
              setShowMenu(false);
            }}
          >
            <IoClose />
          </button>
        ) : (
          <button
            className="text-3xl md:hidden"
            onClick={() => {
              setShowMenu(true);
            }}
          >
            <ImMenu />
          </button>
        )}
      </div>
      <div
        className={
          (showMenu ? "block" : "hidden") +
          " md:flex p-0 md:pr-12 flex-col md:flex-row bg-gray-300 md:self-end font-bold uppercase"
        }
      >
        <NavLink
          to="/project-one"
          className="p-3 block"
          activeClassName="bg-gray-200"
          onClick={() => setShowMenu(false)}
        >
          <div className="w-full h-3 absolute -bottom-3 bg-gray-200 left-0"></div>
          Project One
        </NavLink>
        <NavLink
          to="/project-two"
          className="p-3 block relative"
          activeClassName="bg-gray-200 shadow"
          onClick={() => setShowMenu(false)}
        >
          <div className="w-full h-3 absolute -bottom-3 bg-gray-200 left-0"></div>
          Project Two
        </NavLink>
        <NavLink
          to="/project-three"
          className="p-3 block relative"
          activeClassName="bg-gray-200 shadow"
          onClick={() => setShowMenu(false)}
        >
          <div className="w-full h-3 absolute -bottom-3 bg-gray-200 left-0"></div>
          Project Three
        </NavLink>

        <NavLink
          to="/project-four"
          className="p-3 block relative"
          activeClassName="bg-gray-200 shadow"
          onClick={() => setShowMenu(false)}
        >
          <div className="w-full h-3 absolute -bottom-3 bg-gray-200 left-0"></div>
          Project Four
        </NavLink>
      </div>
    </div>
  );
};

export default Navigation;
