import React from "react";

import "./index.css";

const Author = ({author, short = false}) => {
  return (
    <a
      target="_blank"
      rel="noreferrer"
      href={author.url}
      className="text-blue-700"
    >

      {short ? author.short : author.name}
    </a>
  );
};

export default Author;
