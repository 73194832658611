const Authors = {
  Moe: {
    name: "Mohammad Al Sharbati",
    url: "https://moealsharbati.weebly.com",
    short:"Mohammad"
  },
  Sofian: {
    name: "Sofian Mustafa",
    url: "https://portfolio-sofianmustafa.netlify.app",
    short:"Sofian"
  },
  Rory: {
    name: "Rory Skipper",
    url: "https://roryskipper123.wixsite.com/portfolio",
    short:"Rory"
  },
  JP:{
    name:"Juan Pablo (JP) Lozano Sarmiento",
    short:"JP",
    url:"/"
  },
  Jian: { 
    name: "Jian Liao",
    url:" https://jliao1998.wixsite.com/jliao/",
    short:"Jian"
  }
};

export default Authors;
