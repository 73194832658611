import React from "react";

import "./index.css";

import Image from "../Image";
import Video from "../Video";

const AssetElement = ({ asset, onLoad = null }) => (
  <>    
    {asset.type === "img" ? (
      <Image asset={asset} onLoad={onLoad} />
    ) : (
      <Video asset={asset} onLoad={onLoad} />
    )}
  </>
);

export default AssetElement;
