import React from "react";

import Block from "../Block";
import Title from "../Title";

import { Project3 } from "../../ideas";
import Authors from "../../authors";

import "./index.css";

import CollapseIdeas from "../CollapseIdeas";

const {
  initial,
  variations,
  selected,
  storyboard,
  development,
  results,
  premise,
} = Project3;

const ProjectThree = () => {
  return (
    <div className="">
      <Title
        title={"I am all Ears | Hand-band and Home-pod"}
        subTitle="Sound awareness for the deaf or hard of hearing"
        authors={[Authors.Moe, Authors.Sofian, Authors.Jian]}
      />
      <CollapseIdeas title="The Premise" ideas={premise} id="premise" />
      <CollapseIdeas title="Initial Ideas" ideas={initial} id="initial" />
      <CollapseIdeas title="Variations" ideas={variations} id="variation" odd />
      <CollapseIdeas title="Selected idea" ideas={selected} id="selected" />
      <CollapseIdeas title="Storyboard" ideas={storyboard} id="storyboard" />
      <CollapseIdeas
        title="Product Design and Development"
        ideas={development}
        id="developing"
      />
      <CollapseIdeas
        title="Results and Final Demo Video"
        ideas={results}
        id="results"
      />
      <Block>That is all.</Block>
    </div>
  );
};

export default ProjectThree;
