import React, { useState, useEffect } from "react";

import Block from "../Block";

import Position1 from "../../images/Position1.png";
import Position2 from "../../images/Position2.png";
import Position3 from "../../images/Position3.png";
import Position4 from "../../images/Position4.png";
import Position5 from "../../images/Position5.png";
import Position6 from "../../images/Position6.png";
import Position7 from "../../images/Position7.png";
import Position8 from "../../images/Position8.png";

import Assets from "../../assets";
import Authors from "../../authors"

import "./index.css";
import AssetBlock from "../AssetBlock";
import Title from "../Title";

const UnicyclistFrames = [
  Position1,
  Position2,
  Position3,
  Position4,
  Position5,
  Position6,
  Position7,
  Position8,
];

const Ideas = [
  {
    title: "Donald Trump Me Button",
    subTitle: "Idea #1",
    asset: Assets.DonaldTrumpButton,
    content: (
      <>
        <p>
          For my first client I chose none other than President Donald J. Trump;
          his wild Twitter rants are a key aspect of his personality and as such
          I decided that Trump's "Me" button would facilitate this for him.
        </p>
        <br />
        <p>
          The idea is simple, Trump will be provided a website featuring a
          single button, big enough for him to see it. Whenever Trump feels the
          urge to rant on twitter, all he has to do is press the button.
        </p>
        <br />
        <p>
          Once pressed, the button will tweet out some tweet as if Trump had
          twitted it himself.
        </p>
      </>
    ),
  },
  {
    title: "The Jocker Me button",
    subTitle: "Idea #2",
    asset: Assets.TheJocker,
    content: (
      <>
        <p>
          For my next idea I wanted to diverge from a traditional button, and so
          I thought about fictional characters that I could design a button for.
          After some thinking I thought of Batman, but I couldn't land an idea.
          I then thought of The Joker and I immiedatly had something in mind.
        </p>
        <br />
        <p>
          Now bare with me, as this idea is as out-there as The Joker himself.
        </p>
        <br />
        <p>
          A website, were you find a cartoon batman tied to a post, you click on
          batman and a bat swings and hits batman, leaving him commically hurt.
        </p>
        <br />
        <p>How did I come up with this idea?</p>
        <br />
        <p>
          I asked myself: What would I catch the Joker doing on the internet?
          And I thought it would be hilarious if I caught the Joker playing this
          ruthless game as he giggles to himself when hitting batman.
        </p>
      </>
    ),
  },
  {
    title: "Edward Scissor Hands Me Button",
    subTitle: "Idea #3",
    asset: Assets.EdwardScissorHands,
    content: (
      <>
        <p>
          I decided to keep searching for fictional characters for my next idea.
          I landed on Edward Scissor Hands. I started brainstorming around his
          Scissor Hands and the bushes he loved to cut.
        </p>
        <br />
        <p>
          It didn't take long before I came to the realization that I could make
          a "paint" game but instead of drawing by painting you draw by trimming
          a bush.
        </p>
      </>
    ),
  },
  {
    title: "Severus Snape Me Button",
    subTitle: "Idea #4",
    asset: Assets.SeverusSnape,
    content: (
      <>
        <p>
          Aside from eering out children for the grand part of the 2000's,
          Severus Snape was well known for his mastery of the Potion arts. He
          could whip up any kind of potions, and even crafted some himself.
        </p>
        <br />
        <p>
          And so Snape's "Me" button is a potion making therapy, so he can relax
          and enjoy the wonders of potion making. All Snape's gotta do is go to
          this website and click to make potions in a big cauldron.
        </p>
      </>
    ),
  },
  {
    title: "Dwight Schrute Me Button",
    subTitle: "Idea #5",
    asset: Assets.DwightSchrute,
    content: (
      <>
        <p>
          As a fan of The Office I could not pass up the opporunity to craft a
          button for one of the classiest characters ever: Dwight K. Schrute
        </p>
        <br />
        <p>
          Dwight is often overlooked and he takes that very dearly. I want to
          help him with this, and so I designed a virtual office for him; where
          all he needs to do to get rewarded for his work, is click.
        </p>
      </>
    ),
  },
  {
    title: "Ryan Reynolds Me Button",
    subTitle: "Idea #6",
    asset: Assets.RyanReynolds,
    content: (
      <>
        <p>
          Perhaps you've missed the increasing popularity of Ryan Reynolds; if
          that's the case, you should know that he has made his way to the #1
          spot on every guys dream list. All the guys wanna be with him.
        </p>
        <br />
        <p>
          This next idea is inspired by all those Ryan Lovers, and is meant to
          aid them in their endavours.
        </p>

        <br />
        <p>
          The website will feature a mirror showing the reflection of our
          beloved Ryan's face. He just came out of the shower and the room is
          steamy. You must click on the mirror to clear the steam and reveal
          your dreams.
        </p>
      </>
    ),
  },
  {
    title: "Karen Me Button",
    subTitle: "Idea #7",
    asset: Assets.Karen,
    content: (
      <>
        <p>
          Have you met Karen? You probably have heard of her, right? No? Well
          let me fill you in.
        </p>
        <br />
        <p>
          Karen is not one specific person, but rather a state of mind. If you
          want to be Karen you must place yourself on the highest pedestal you
          got. You must walk with pride and demand the world to bend to your
          very will.
        </p>
        <br />
        <p>
          As a Karen you will want some practice, the world is full of people
          who will defy your word. We can't let that happen. And so I came up
          with a Karen simulator.
        </p>
        <br />
        <p>
          The simulator will feature a looping storyline, in the sketch I
          decided on the classic "Getting a coffee". The story line will be
          presented from the point of view of the peson getting the coffee.
          That's you!
        </p>
        <br />
        <p>
          The story will have normal human interactions, you will be polite and
          understanding of any inconvinience. But if you click anywhere on the
          screen you will activate a Karen action. This action will depend on
          where you are in the story, but you can be sure no one will be happy
          to hear it.
        </p>
      </>
    ),
  },
  {
    title: "Pirate Me Button",
    subTitle: "Idea #8",
    asset: Assets.Pirate,
    content: (
      <>
        <p>
          Ahhhh the life of a pirate, cruising the open sea with your trusty
          crew. Some long days and nights at sea, what better way to pass the
          time than opening a rum bottle and looking at the sea.
        </p>
        <br />
        <p>
          This next idea is for such men, for their convenience they will
          already be looking out into the open sea. In the distance you spot a
          manatee.
        </p>

        <br />
        <p>
          Go ahead and give it a click, you shall see that it's that easy to
          drink. And look at that, that manatee is starting to look aufly fly.
          Give it a few more clicks. That manatee is now a gorgeous mermaid. You
          are welcome.
        </p>
      </>
    ),
  },
  {
    title: "The Hulk Me Button",
    subTitle: "Idea #9",
    asset: Assets.Hulk,
    content: (
      <>
        <p>
          The Hulk loves one thing above all. Yes even above Agent Romanoff.
          That's right, he loves to smash. He'll smash anything thats within his
          reach.
        </p>
        <br />
        <p>
          And so it is that idea wich inspires this next one. A hulk smah
          button. You'll be placed infront of an item and you have to click to
          smash!!
        </p>
      </>
    ),
  },
  {
    title: "Dr. Frankenstein Me Button",
    subTitle: "Idea #10",
    asset: Assets.Frank,
    content: (
      <>
        <p>
          A man playing God is always fun. In this idea you get to play as Dr.
          Frankenstein; experience the true rush of bringing life back from the
          Death
        </p>
        <br />
        <p>
          This features a fully equipped revival bed, all plugged in and a
          corpse made from corpses ready to be revived. All you have to do is
          click and electricity will rish through the corpse, bringing it back
          to life.
        </p>
      </>
    ),
  },
];

const Ideas2 = [
  {
    title: "Pirate Me Button",
    subTitle: "Idea #1",
    asset: Assets.PirateEnhanced,
    content: (
      <>
        <p>
          Adding onto my initial Pirate idea, I decided to spice things up a
          bit.
        </p>
        <br />
        <p>
          The premise is the same, however if you click while you are taking a
          drink you drop your rum. And you start over.
        </p>
      </>
    ),
  },
  {
    title: "Pirate Me Button",
    subTitle: "Idea #2",
    asset: Assets.PirateMop,
    content: (
      <>
        <p>
          Another not so fun pirate activity is moping the ship's floors. This
          idea is inspired by such activity.
        </p>
        <br />
        <p>
          You are presented with a dirty floor, a mop, and a bucket. You must
          click on the bucket to clean the mop, and click on the floor to clean
          the floor.
        </p>
      </>
    ),
  },
  {
    title: "Severus Snape Me Button",
    subTitle: "Idea #3",
    asset: Assets.SeverusSnapeEnhanced,
    content: (
      <>
        <p>
          This is a more detailed sketch on my previous Sevrus Snape idea. This
          one also adds the risk of loosing.
        </p>
        <br />
        <p>
          The website presents you with a cauldron and a whole bunch of
          ingredients. You can see the next ingredient on the bottom right of
          the screen. If you place the wrong ingredient, the cauldron overflows
          and the game restarts.
        </p>
      </>
    ),
  },
  {
    title: "Edward Scissor Hands Me Button",
    subTitle: "Idea #4",
    asset: Assets.EdwardScissorHandsEnhanced,
    content: (
      <>
        <p>
          This is a smal variation on my original idea. In this iteration I
          decided it would be fun if the leaves of the bush grew back over time,
          after being cut off.
        </p>
      </>
    ),
  },
  {
    title: "Edward Scissor Hands Me Button",
    subTitle: "Idea #5",
    asset: Assets.EdwardScissorHandsEnhanced,
    content: (
      <>
        <p>
          Further elaborating on the Edward Scissor Hands idea, I thought of
          adding some threat to the mix.
        </p>
        <br />
        <p>
          Jim is hidding behind the bush. You must be careful not to uncover his
          face otherwise you loose.
        </p>
      </>
    ),
  },
  {
    title: "The Hulk Me Button",
    subTitle: "Idea #6",
    asset: Assets.EdwardScissorHandsEnhanced,
    content: (
      <>
        <p>
          In this variation of The Hulk Me Button, you must be careful not to
          accidentally smash one of your team mates, as the may run in front of
          you on occasion.
        </p>
      </>
    ),
  },
  {
    title: "Unicycle Me Button",
    subTitle: "Idea #7",
    asset: Assets.Unicyclist,
    content: (
      <>
        <p>
          My teammate Rory came up with this idea. You must balance a unicyclist
          by clicking in the direction of which he is falling.
        </p>
        <br />
        <p>
          He later came up with a couple variations of this. The first is that
          you must click in the opposite direction of which he is falling. The
          second is that you must balance the unicyclist as well as keep
          asteroids from hitting him.
        </p>
      </>
    ),
  },
];

const ProjectOne = () => {
  const [currentFrame, setCurrentFrame] = useState(0);

  useEffect(() => {
    const player = setTimeout(() => {
      if (currentFrame < UnicyclistFrames.length - 1)
        setCurrentFrame(currentFrame + 1);
      else setCurrentFrame(0);
    }, 100);

    return () => {
      clearTimeout(player);
    };
  });

  return (
    <div>
      <Title
        title={'Tipsy Unicycle - "Me" Button'}
        subTitle="Project 1"
        authors={[
            Authors.Rory
        ]}
      />
      <Block reverse>
        <div>
          <h1 className="text-xl font-bold">The Premise...</h1>
          <p className="text-lg">
            <br />
            Sketch, design, implement and document an application where the
            central interaction is with a button, such that the interaction
            represents some aspect of your client’s personality or character.
          </p>
        </div>
      </Block>
      <Block>
        <h1 className="text-4xl font-bold">The initial ideas</h1>
      </Block>
      {Ideas.map((idea, i) => (
        <AssetBlock
          key={i}
          asset={idea.asset}
          title={idea.title}
          subTitle={idea.subTitle}
          odd={!(i % 2)}
          reverse={!(i % 2)}
        >
          {idea.content}
        </AssetBlock>
      ))}
      <Block reverse>
        <div className="w-full">
          <h1 className="text-xl font-bold">A second iteration</h1>
        </div>
      </Block>
      {Ideas2.map((idea, i) => (
        <AssetBlock
          key={i}
          asset={idea.asset}
          title={idea.title}
          subTitle={idea.subTitle}          
          reverse={(i % 2)}
        >
          {idea.content}
        </AssetBlock>
      ))}
      <Block reverse>
        <div className="md:pl-24 md:pr-24">
          <h1 className="text-xl font-bold">Decisions</h1>
          <br />
          <p>
            For me, the gathering of ideas started with thinking of famous
            people; as I was doing so, I was looking for a way to design a "Me"
            button for that person. I was looking for ways to relate it to their
            personality, so even though I could name lots of famous people, I
            didn't know many of them well enough to conjure up an idea for their
            "Me" button.
          </p>
          <br />
          <p>
            When I stumbled onto Donald Trump, I knew I could come up with
            something for him, after all he has such a strong personality. Given
            this
            <i> in</i> into the world of the "Me" button, I realized there was
            probably more of chance for me to think of something if I looked for
            fictional characters, after all they are full of personality and I
            know a lot of them.
          </p>
          <br />
          <p>
            Throughout the sketching of my ideas, I found myself erasing a lot
            of the time. This made it take considerably longer to finish a
            sketch, however I began to find techniques to get the right shapes
            and decreased the awfully high erase vs stroke ratio; it's still
            awfully high, but progress was made nonetheless.
          </p>
          <br />
          <p>
            As a team, we exchanged our initial ideas and decided to develop 10
            more based on our own individual ideas. Now I realize that perhaps a
            better way would've been to develop on the ideas of each other as
            well. That way we would've further enriched the ideas.
          </p>
          <br />
          <p>
            We decided to implement Rory's unicycle idea. It was a clever one
            and the concept was easy enough to implement within the project's
            timeframe.
          </p>
          <p>
            We started by implementing the basic balancing game, and if time
            permitted we would implement the asteroid functionality. Lucky for
            us, time did permit! I got the balancing up and running, and Rory
            implemented the asteroid functionality.
          </p>
          <br />
        </div>
      </Block>
      <Block row>
        <div className="md:pr-24">
          <h1 className="text-xl font-bold">Results</h1>
          <p>
            You can find this project live at{" "}
            <a
              className="text-blue-700"
              href="http://tipsy-unicycle.glitch.me/"
            >
              tipsy-unicycle.glitch.me
            </a>
            , it's source code is hosted on github and you can find it{" "}
            <a
              className="text-blue-700"
              href="https://github.com/7rory768/CPSC-581-Tipsy-Unicycle"
            >
              here
            </a>
            . And you can get my sketchbook for this project{" "}
            <a className="text-blue-700" href="/Project-1-sketchbook.pdf">
              here
            </a>
          </p>
          <br />
          <p>
            Rory recorded a video to showcase the implementation of "Tipsy
            Unicycle", I have attached it here for your convenience.
          </p>
        </div>
        <div>
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/5-AlFOK47GQ"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </Block>
    </div>
  );
};

export default ProjectOne;
