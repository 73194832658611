import "./index.css";

import ProjectOne from "../ProjectOne";
import ProjectTwo from "../ProjectTwo";
import ProjectThree from "../ProjectThree";
import ProjectFour from "../ProjectFour";
import Navigation from "../Navigation";

import {
  Switch,
  Route,
  BrowserRouter as Router,
  Redirect,
} from "react-router-dom";

function App() {
  return (
    <Router>
      <div className="">
        <Navigation />
        <Switch>
          <Route path="/project-four">
            <ProjectFour />
          </Route>
          <Route path="/project-one">
            <ProjectOne />
          </Route>
          <Route path="/project-two">
            <ProjectTwo />
          </Route>
          <Route path="/project-three">
            <ProjectThree />
          </Route>
          <Route exact path="/">
            <Redirect to="/project-one" />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
