import React, { useState } from "react";

import Block from "../Block";
import Asset from "../Asset";
import { AiFillCloseCircle } from "react-icons/ai";

import "./index.css";
import { AiFillCaretLeft, AiFillCaretRight } from "react-icons/ai";
import Author from "../Author";

const NavigationButton = ({
  back = true,
  disabled = false,
  onClick = null,
  invert = false,
}) => {
  return (
    <div className="w-12 md:w-24 text-4xl text-gray-600 flex align-middle justify-center">
      <button
        className={
          disabled
            ? (invert ? "text-gray-900" : "text-gray-400") + " cursor-default"
            : ""
        }
        onClick={onClick}
        disabled={disabled}
      >
        {back ? <AiFillCaretLeft /> : <AiFillCaretRight />}
      </button>
    </div>
  );
};

const AssetBlock = ({
  asset,
  title = null,
  subTitle = null,
  children,
  reverse = false,
  author = null,
  badge = null,
  id = null,
}) => {
  const [fullscreen, setFullscreen] = useState(false);
  const [currentAsset, setCurrentAsset] = useState(0);

  const assets = asset
    ? [asset.image, asset.video, asset.video_fail, asset.any]
        .flat()
        .filter((asset_internal) => asset_internal)
    : null;

  return (
    <div
      className="relative"
      id={id}
      onWheel={() => fullscreen && setFullscreen(false)}
    >
      <Block row reverse={reverse}>
        {badge && (
          <div className="text-sm absolute top-0 right-0 bg-gray-800 text-gray-200 flex items-center p-2">
            {badge}
          </div>
        )}
        <div className={"p-0 md:p-12 flex flex-col justify-center" + (asset ? " md:w-2/4 " : "")}>
          {subTitle && <h1 className="">{subTitle}</h1>}
          {title && <h1 className="text-xl font-bold">{title}</h1>}
          {author && (
            <h1>
              By: <Author author={author} />
            </h1>
          )}
          <div>
            <br />
            {children}
          </div>
        </div>
        {assets && assets.length > 0 && (
          <div className="p-0 md:p-12 flex flex-col align-middle justify-center md:w-2/4">
            <h1
              className={"text-gray-800 border-gray-800 pb-1 border-b-2 mb-3"}
            >
              {assets[currentAsset].alt}
            </h1>
            <div
              className={
                (fullscreen
                  ? "bg-gray-800 fixed inset-0 w-screen h-screen z-30"
                  : "") + " flex flex-col md:flex-row justify-stretch "
              }
            >
              {fullscreen && (
                <div className="bg-gray-900 h-16 w-full md:h-full md:w-16 flex items-center">
                  <div className="">
                    <h1
                      className={
                        " text-center font-bold text-gray-400 md:w-96 box-content md:transform md:origin-center md:-rotate-90 md:relative md:inset-0 md:-translate-x-40"
                      }
                    >
                      {assets[currentAsset].alt}
                    </h1>
                  </div>
                </div>
              )}

              <div className="flex flex-grow">
                {assets.length > 1 && (
                  <NavigationButton
                    invert={fullscreen}
                    disabled={currentAsset === 0}
                    onClick={() => setCurrentAsset(currentAsset - 1)}
                  />
                )}
                <div
                  className={
                    (fullscreen ? "w-full flex" : "max-h-96  cursor-pointer") +
                    ""
                  }
                  onClick={() => !fullscreen && setFullscreen(true)}
                >
                  <Asset asset={assets[currentAsset]} />
                </div>
                {assets.length > 1 && (
                  <NavigationButton
                    invert={fullscreen}
                    disabled={currentAsset === assets.length - 1}
                    back={false}
                    onClick={() => setCurrentAsset(currentAsset + 1)}
                  />
                )}
              </div>
              {fullscreen && (
                <div className="absolute text-gray-600 text-4xl top-0 right-0 p-6 z-40">
                  <button onClick={() => setFullscreen(false)}>
                    <AiFillCloseCircle />
                  </button>
                </div>
              )}
            </div>
          </div>
        )}
      </Block>
    </div>
  );
};

export default AssetBlock;
