import Assets from "./assets";
import Authors from "../../authors";

import Idea from "../Idea";

const Ideas = {
  premise: [
    {
      content: (
        <>
          <p className="text-lg pl-6">
            For this project, you will create a novel interface to augment
            in-person and/or remote communication and collaboration. If the
            system is relevant to any type of communication, conversation,
            collaboration etc, then it should be fine.
          </p>
          <br />
          <p className="text-lg pl-6">
            You can use any types of technology for this last project. For
            example, AR, VR, mobile phone, desktop screens, physical prototype,
            or combination of any of them.
          </p>
        </>
      ),
    },
  ],
  initial: [
    {
      title: "Collaborative Movie/TV Streaming with chat",
      subTitle: "Idea #1 - Enriching friendships",
      author: Authors.Sofian,
      asset: Assets.CollaborativeMovieTVStreaming,
      content: (
        <>
          <p>
            This idea makes it possible for a group of friends to watch a movie
            tegether via voice chat.
          </p>
        </>
      ),
    },
    {
      title: "Culture Hub",
      subTitle: "Idea #2 - Enriching/Sharing culture",
      author: Authors.JP,
      asset: Assets.CultureHub,
      content: (
        <>
          <p>
            A social network with an AI guided navigation, people can explore
            within a wide variety of topics
          </p>
        </>
      ),
    },
    {
      title: "Friend Detector",
      subTitle: "Idea #3 - Bringing people together",
      author: Authors.Moe,
      asset: Assets.FriendDetector,
      content: (
        <>
          <p>
            Get notified whenever a friend from your contacts list is near you.
          </p>
        </>
      ),
    },
    {
      title: "Friend Radio",
      subTitle: "Idea #4 - Sharing music",
      author: Authors.JP,
      asset: Assets.FriendRadio,
      content: (
        <>
          <p>
            A never ending private radio station where tracks can be added by
            any member.
          </p>
        </>
      ),
    },
    {
      title: "Interview Prep",
      subTitle: "Idea #5 - Important conversations",
      author: Authors.Sofian,
      asset: Assets.InterviewPrep,
      content: (
        <>
          <p>
            Prepare yourself for your interview by going through mock interviews
            and getting feedback on various aspects of your responses
          </p>
        </>
      ),
    },
    {
      title: "Language Translator",
      subTitle: "Idea #6 - Breaking through language barriers",
      author: Authors.Moe,
      asset: Assets.InterviewPrep,
      content: (
        <>
          <p>Translate speech around you from any language</p>
        </>
      ),
    },

    {
      title: "Metaverse Shopping",
      subTitle: "Idea #7 - Remote connections",
      author: Authors.Jian,
      asset: Assets.MetaverseShopping,
      content: (
        <>
          <p>Shop at any store with the use of VR</p>
        </>
      ),
    },
    {
      title: "No Poverty Land",
      subTitle: "Idea #8 - Collabration",
      author: Authors.Jian,
      asset: Assets.NoPovertyLand,
      content: (
        <>
          <p>
            A platform intended as a think-tank and wholesale to help the world
            get rid of poverty
          </p>
        </>
      ),
    },
    {
      title: "Off Your Chest",
      subTitle: "Idea #9 - Theraputic and Conversation starter",
      author: Authors.Jian,
      asset: Assets.NoPovertyLand,
      content: (
        <>
          <p>
            A platform where people can post audio recordings anonymously, and
            start conversations. Inteded as a safe space where anyone can tell
            their story.
          </p>
        </>
      ),
    },
    {
      title: "Voice Recognizer Audio Player",
      subTitle: "Idea #10 - Ease of access",
      author: Authors.Jian,
      asset: Assets.NoPovertyLand,
      content: (
        <>
          <p>
            Play any song from your favorite streaming service via voice
            commands
          </p>
        </>
      ),
    },
  ],
  variations: [
    {
      title: "Audio Visualizer",
      subTitle: (
        <p>
          Variation #1 - on <Idea id="initial-9" name={'"Off Your Chest"'} />
        </p>
      ),
      author: Authors.Moe,
      asset: Assets.AudioVisualizer,
      content: (
        <>
          <p>
            Provide visual feedback based on the amplitude of sound as the
            recording is being made.
          </p>
          <br />
        </>
      ),
    },
    {
      title: "Critical Resources",
      subTitle: (
        <p>
          Variation #2 - on <Idea id="initial-9" name={'"Off Your Chest"'} />
        </p>
      ),
      author: Authors.Sofian,
      asset: Assets.CriticalResources,
      content: (
        <>
          <p>
            Automatically detect posssible intentions of self harm or severe
            depression, provide critical resources
          </p>
        </>
      ),
    },
    {
      title: "Natural Language Understanding",
      subTitle: (
        <p>
          Variation #3 - on <Idea id="initial-9" name={'"Off Your Chest"'} />
        </p>
      ),
      author: Authors.Jian,
      asset: Assets.NaturalLanguageUnderstanding,
      content: (
        <>
          <p>
            Utilize AI to gather information such as sentiment, keywords,
            emotion
          </p>
        </>
      ),
    },

    {
      title: "Off Your Chest Device",
      subTitle: (
        <p>
          Variation #4 - on <Idea id="initial-9" name={'"Off Your Chest"'} />
        </p>
      ),
      author: Authors.Jian,
      asset: Assets.OffYourChestDevice,
      content: (
        <>
          <p>
            A device intended to be placed in a public space so that anyone can
            share their thoughts.
          </p>
        </>
      ),
    },
    {
      title: "Off Your Chest Device App",
      subTitle: (
        <p>
          Variation #5 - on <Idea id="initial-9" name={'"Off Your Chest"'} />
        </p>
      ),
      author: Authors.Jian,
      asset: Assets.OffYourChestDeviceApp,
      content: (
        <>
          <p>Interface for the off your chest public device</p>
        </>
      ),
    },

    {
      title: "Posts By Category",
      subTitle: (
        <p>
          Variation #6 - on <Idea id="initial-9" name={'"Off Your Chest"'} />
        </p>
      ),
      author: Authors.Moe,
      asset: Assets.PostsByCategory,
      content: (
        <>
          <p>Browse posts via categories</p>
        </>
      ),
    },

    {
      title: "Posts By Keyword",
      subTitle: (
        <p>
          Variation #7 - on <Idea id="initial-9" name={'"Off Your Chest"'} />
        </p>
      ),
      author: Authors.Jian,
      asset: Assets.PostsByKeywords,
      content: (
        <>
          <p>Browse posts via keywords</p>
        </>
      ),
    },

    {
      title: "Posts Recommendations",
      subTitle: (
        <p>
          Variation #8 - on <Idea id="initial-9" name={'"Off Your Chest"'} />
        </p>
      ),
      author: Authors.Sofian,
      asset: Assets.PostRecommendation,
      content: (
        <>
          <p>Get recommended posts based on yuur own posts and browsing</p>
        </>
      ),
    },

    {
      title: "Posts Scheduling",
      subTitle: (
        <p>
          Variation #9 - on <Idea id="initial-9" name={'"Off Your Chest"'} />
        </p>
      ),
      author: Authors.Moe,
      asset: Assets.PostScheduling,
      content: (
        <>
          <p>Schedule posts up to a week in advance</p>
        </>
      ),
    },
    {
      title: "UserRating",
      subTitle: (
        <p>
          Variation #10 - on <Idea id="initial-9" name={'"Off Your Chest"'} />
        </p>
      ),
      author: Authors.Sofian,
      asset: Assets.UserRating,
      content: (
        <>
          <p>User ratings are public and are computed based on helpfullness</p>
        </>
      ),
    },
  ],
  storyboard: [
    {
      title: "Off Your Chest",
      subTitle: "Storyboard and Concept Video",
      content: (
        <div className="w-full flex items-center justify-center">
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/1ahWtmF86gY"
            title="Off Your Chest Storyboard"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
            allowfullscreen
          ></iframe>
        </div>
      ),
    },
  ],
  results: [
    {
      title: "Off your Chest",
      content: (
        <div className="w-full flex">
          <div>
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/iW1Dls5oToA"
              title="Off Your Chest Demo Video"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
              allowfullscreen
            ></iframe>
          </div>
          <div className="p-6">
            <p>
              Here we present the Final Demo Video for our project. Our final
              code for the device app can be found{" "}
              <a
                className="text-blue-700"
                href="https://github.com/JLiao98/off-my-chest-device"
                target="_blank"
                rel="noreferrer"
              >
                here
              </a>
              . The source code for the platform can be found{" "}
              <a
                className="text-blue-700"
                href="https://github.com/Tutadit/Off-My-Chest"
                target="_blank"
                rel="noreferrer"
              >
                here
              </a>
              .
            </p>
          </div>
        </div>
      ),
    },
  ],
};

export default Ideas;
