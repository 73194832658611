import React from "react";

import "./index.css";

const Image = ({ asset, onLoad = null }) => (
  <img
    src={asset.src}
    className="max-h-full mx-auto shadow"
    alt={asset.alt}
    onLoad={onLoad}
  />
);

export default Image;
