import React from "react";

import "./index.css";

const Idea = ({ id, name }) => {
  return (
    <a
      href={"#" + id}
      className="text-blue-700"
    >
      {name}
    </a>
  );
};

export default Idea;
