import React from "react";

import './index.css';

const Video = ({ asset, onLoad = null }) => (
    <video
      key={asset.src}
      className="max-h-full mx-auto shadow"
      onCanPlayThrough={onLoad}
      controls={asset.noPlay}
      preload="auto"
      autoPlay={!asset.noPlay}
      disablePictureInPicture
      loop
      playsInline
      height="100%"
      muted
    >
      <source src={asset.src} type="video/mp4" />
    </video>
  );

export default Video;
